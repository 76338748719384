<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-25 12:25:54
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-09 23:54:12
-->
<template>
  <div class="base">
    <div class="coumt">
      <div>当前位置：首页  >  课程  >  课程详情</div>
    </div>
    <div class="case">
      <div class="video">
        <div style="position: relative;">
          <!-- <video ref="videoPlayer" :src="obj.video_url" :poster="obj.thumbnail" autoplay controls controlslist="nodownload"></video> -->
          <video-player :vData="vData" />
          <!-- <video-player class="video-player vjs-big-play-centered" ref="videoPlayer" :options="playerOptions" :playsinline="true" @play="onPlayerPlay($event)" /> -->
        </div>
        <div class="dest">{{videoName}}</div>
      </div>
      <div class="right">
        <div class="catalogue">目录</div>
        <div class="list">
          <div class="item" v-for="item in list">
            <div class="big">{{item.name}}</div>
            <div class="sition">
              <div class="cell" :class="{ active: d.id == id }" v-for="d in item.videos" @click="goVideo(d)">
                <div class="icon" v-if="d.id == id"><img src="@/assets/images/onplay.png" alt=""></div>
                <div class="icon" v-else><img src="@/assets/images/play.png" alt=""></div>
                <div class="context">
                  <div class="title">{{d.name}}</div>
                  <div class="time">{{d.video_time == null ? '' : ((d.video_time / 3600 - (d.video_time / 3600 % 1)).toFixed(0) + 'h' + ((d.video_time % 3600) / 60).toFixed(0) + 'min')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogTableVisible" append-to-body width="400px" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" custom-class="diaAtrel">
      <div class="contents">VIP用户才可以查看哦</div>
      <div class="footers">
        <div class="item q" @click="(dialogTableVisible=false)">取消</div>
        <div class="item c" @click="$router.push({name: 'advisory'})">去咨询</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import http from '@/util/http'

import videoPlayer from '@/components/VideoPlayer.vue';

export default{
  components: { videoPlayer },
  data(){
    return{
      kid: '', // 课程id
      id: '', // 视频id
      list: [],
      obj: '',
      dialogTableVisible: false,
      videoName: '',
      vData:{
        hlsurl: "", //视频url地址
        cameraId: "fid0", //id
      }
    }
  },
  mounted() {
    this.kid = this.$route.query.kid;
    this.id = this.$route.query.id;
    this.getList();
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    async getList() {
      var params = {
        id: this.kid
      }
      var { data } = await http.post('/api/course/getContents', params);
      data.forEach(item => {
        for (let index = 0; index < item.videos.length; index++) {
          var e = item.videos[index];
          //把https转为http
          if(e.video_url && e.video_url.includes('https')) {
            e.video_url = e.video_url.replace('https','http')
          }
          if(e.id == this.id){
            var userInfo = this.$store.state.userInfo;
            if(e.is_vip == 1){
              if (!this.$store.getters.uniqid){
                this.$root.Bus.$emit('showLogin');
                return;
              }else{
                if(userInfo.is_vip == 0){
                  this.dialogTableVisible = true;
                  return;
                }else{
                  this.vData.hlsurl = e.video_url
                  this.videoName = e.name
                }
              }
            }else{
              this.vData.hlsurl = e.video_url
              this.videoName = e.name
            }
          }
        }
      })
      this.list = data;
    },
    onPlayerPlay(player) {
      console.log('player play!', player)
    },
    //把https变成http
    goVideo(item){
      var userInfo = this.$store.state.userInfo;
      if(item.is_vip == 1){
        if (!this.$store.getters.uniqid){
          this.$root.Bus.$emit('showLogin');
          return
        }else{
          if(userInfo.is_vip == 0){
            this.dialogTableVisible = true;
            return;
          }else{
            this.vData.hlsurl = item.video_url.replace('https','http')
            this.videoName = item.name
            this.id = item.id;
          }
        }
      }else{
        this.vData.hlsurl = item.video_url.replace('https','http')
        this.videoName = item.name
        this.id = item.id;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.base{
  padding-top: 80px;
  width: 1200px;
  margin: 0 auto;
  .case{
    width: 100%;
    display: flex;
    .right{
      width: 320px;
      background-color: #2C2C32;
      .list{
        width: 100%;
        height: 535px;
        color: #fff;
        padding: 30px 0;
        overflow-y: auto;
        .item{
          border-bottom: 1px solid #3F3F46;
          padding: 0 20px;
          .sition{
            .cell{
              display: flex;
              border-bottom: 1px dashed #3F3F46;
              padding: 20px 0;
              cursor: pointer;
              &.active{
                color: #FF6A29;
              }
              &:last-child{
                border-bottom: 0;
              }
              .context{
                padding-left: 4px;
                .time{
                  color: #999999;
                  padding-top: 9px;
                }
                .title{
                  font-size: 16px;
                }
              }
              .icon{
                width: 20px;
                height: 20px;
                img{
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          &:first-child{
            .big{
              padding-top: 0;
            }
          }
          .big{
            font-size: 18px;
            padding-top: 20px;
          }
        }
          /* 滚动条样式 */
          &::-webkit-scrollbar{
            width: 5px;
            height: 5px;
          }
          &::-webkit-scrollbar-track-piece{
            background-color: rgba(0, 0, 0, 0.2);
            -webkit-border-radius: 6px;
          }
          &::-webkit-scrollbar-thumb:vertical{
            height: 10px;
            background-color: rgba(125, 125, 125, 0.7);
            -webkit-border-radius: 6px;
          }
          &::-webkit-scrollbar-thumb:horizontal{
            width: 10px;
            background-color: rgba(125, 125, 125, 0.7);
            -webkit-border-radius: 6px;
          }
      }
      .catalogue{
        width: 100%;
        height: 60px;
        line-height: 60px;
        padding-left: 23px;
        color: #FFF;
        border-bottom: 1px solid #3F3F46;
      }
    }
    .video{
      flex: 1;
      video{
        width: 880px;
        height: 495px;
      }
      .dest{
        width: 100%;
        height: 100px;
        line-height: 100px;
        padding-left: 30px;
        background-color: #2C2C32;
        color: #FFF;
        font-size: 20px;
      }
    }
  }
  .coumt{
    color: #FFF;
    padding-bottom: 10px;
  }
}
</style>